"use client";

/* eslint-disable react/jsx-no-useless-fragment -- needed to be able to return error messages */
import { Link } from "@/components/Link/Link.component";
import { useSite } from "@/contexts/site/site.context";
import { useUser } from "@/contexts/user/user.context";
import type { ErrorMessageStatus } from "@/services/utilities/fetchable";
import { inlineJsx } from "@/ts/locale/inline-jsx";
type ErrorMessageProps = {
  readonly status: ErrorMessageStatus;
};
export function EmailErrorMessage({
  status
}: ErrorMessageProps) {
  const {
    locale
  } = useSite();
  const {
    urls: {
      loginAndReturnUrl
    }
  } = useUser();
  function getErrorMessageElementByStatus(): JSX.Element {
    const logInLink = <Link className="underline hover:no-underline" href={loginAndReturnUrl}>
        {{
        da: "Log ind",
        de: "Anmelden",
        en: "Log in",
        no: "Logg inn",
        sv: "Logga in"
      }[locale]}
      </Link>;
    switch (status) {
      case "USER_ALREADY_EXISTS":
        return <>
            {{
            da: inlineJsx`Der findes allerede en bruger med denne e-mailadresse. ${logInLink}.`,
            de: inlineJsx`Es gibt bereits einen Benutzer mit dieser E-Mail-Adresse. ${logInLink}.`,
            en: inlineJsx`A user with this email address already exists. ${logInLink}.`,
            no: inlineJsx`Det finnes allerede en bruker med denne e-postadressen. ${logInLink}.`,
            sv: inlineJsx`Det finns redan en användare med denna e-postadress. ${logInLink}.`
          }[locale]}
          </>;
      default:
        return <>
            {{
            da: "Der skete en fejl. Prøv venligst igen senere.",
            de: "Ein Fehler ist aufgetreten. Probieren Sie Später nochmal.",
            en: "An error has occurred. Please try again later.",
            no: "Det skjedde en feil. Vennligst prøv igjen senere.",
            sv: "Ett fel har inträffat. Försök igen senare."
          }[locale]}
          </>;
    }
  }
  return <span className="absolute select-none text-xs text-error-500" data-sentry-component="EmailErrorMessage" data-sentry-source-file="EmailErrorMessage.component.tsx">
      {getErrorMessageElementByStatus()}
    </span>;
}

/* eslint-enable */