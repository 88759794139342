import "client-only";

import { clientFetcher } from "@/services/utilities/fetch/client-fetcher";
import { httpStatusCodes } from "@/services/utilities/http/http-status-code.constants";
import type {
  CreateProfileResponse,
  CreateProfileResult,
  CreateProfileProps,
} from "../profile-api-types";

const getErrorResponse = (status: number, response: CreateProfileResult) => {
  if (status === httpStatusCodes.conflict) {
    return "USER_ALREADY_EXISTS";
  }
  if (status === httpStatusCodes.badRequest) {
    return "password" in response
      ? "PASSWORD_REQUIREMENTS_NOT_MET"
      : "BLACKLISTED";
  }

  return "FAILED";
};
export async function createProfile({
  apiDomain,
  dto,
  recaptchaToken,
}: CreateProfileProps): Promise<CreateProfileResponse> {
  try {
    const response = await clientFetcher(`${apiDomain}/v4/user`, {
      body: dto,
      method: "POST",
      recaptchaToken,
    });
    const result: CreateProfileResult = await response.json();
    if (!response.ok) {
      const status = getErrorResponse(response.status, result);
      return { status };
    }

    return {
      status: result.hasDomainAccess ? "CREATED_DOMAIN" : "CREATED_SHOP",
    };
  } catch {
    return { status: "FAILED" };
  }
}
