import type { FetchProfileModel } from "../models/fetch-profile-model";
import type { FetchProfileDto } from "./fetch-profile-dto.types";

export function mapToFetchProfileDto(
  model: FetchProfileModel,
): FetchProfileDto {
  return {
    email: model.email,
  };
}
