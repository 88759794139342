"use client";

import type { ComponentType, FormEvent } from "react";
import { useEffect, useRef, useState } from "react";
import type { SubmitButtonProps } from "@/components/Button/components/SubmitButton/SubmitButton.component";
import { ErrorMessage } from "@/components/ErrorMessage/ErrorMessage.component";
import { EmailErrorMessage } from "@/components/Input/Email/components/EmailErrorMessage.component";
import { Email } from "@/components/Input/Email/Email.component";
import { Name } from "@/components/Input/Name/Name.component";
import { Password } from "@/components/Input/Password/Password.component";
import { Link } from "@/components/Link/Link.component";
import { useSite } from "@/contexts/site/site.context";
import type { Fetchable, ErrorMessageStatus } from "@/services/utilities/fetchable";
import { initial, isPending, pending, hasFailed } from "@/services/utilities/fetchable";
import { inlineJsx } from "@/ts/locale/inline-jsx";
import { CheckboxWithLabel } from "../Checkbox/CheckboxWithLabel.component";
export type CreateUserFormData = {
  readonly email: string;
  readonly password: string;
  readonly firstName: string;
  readonly lastName: string;
};
type CreateUserFormProps<FormStateType, EmailStateType> = {
  readonly initialFormState?: Fetchable<FormStateType>;
  readonly SubmitButton: ComponentType<SubmitButtonProps>;
  readonly onSubmit: (data: CreateUserFormData) => Promise<Fetchable<FormStateType>>;
  readonly onEmailValidation: (email: string) => Promise<Fetchable<EmailStateType>>;
};
export function CreateUserForm<FormStateType, EmailStateType>({
  initialFormState = initial(),
  onSubmit,
  SubmitButton,
  onEmailValidation
}: CreateUserFormProps<FormStateType, EmailStateType>) {
  const {
    locale,
    urls
  } = useSite();
  const formRef = useRef<HTMLFormElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [formState, setFormState] = useState<Fetchable<FormStateType>>(initialFormState);
  const [emailState, setEmailState] = useState<Fetchable<EmailStateType>>(initial());
  const termsAndConditionsLink = <Link className="text-primary hover:underline" href={urls.termsAndConditions} rel="noreferrer" openInNewTab>
      {{
      da: "vilkårene",
      de: "Nutzungsbedingungen",
      en: "terms and conditions",
      no: "vilkårene",
      sv: "villkoren"
    }[locale]}
    </Link>;
  function checkFormValidity() {
    setIsFormValid(Boolean(formRef.current?.checkValidity()));
  }
  useEffect(() => {
    emailRef.current?.setCustomValidity(hasFailed(emailState) ? emailState.errorMessage : "" // Setting customValidity to empty string means valid :)
    );
    checkFormValidity();
  }, [emailState]);
  async function validateEmail(email: string) {
    const isValid = Boolean(emailRef.current?.checkValidity());

    // Clear emailState and only call onEmailValidation if it is a valid, non-empty email
    if (!isValid || !email) {
      setEmailState(initial());
      return;
    }
    setEmailState(pending());
    setEmailState(await onEmailValidation(email));
  }
  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault(); // Stops the browser from refreshing the page upon submitting the form.

    if (!isFormValid) {
      return;
    }

    // FormData doesn’t include disabled fieldsets, so we have to get the data BEFORE setting pending state,
    // because this disables the input fields: https://www.silvestar.codes/articles/form-data-doesn-t-work-well-with-disabled-fieldset-elements/
    const formData = new FormData(formRef.current ?? undefined);
    const payload = {
      email: (formData.get("email") as string),
      firstName: (formData.get("firstName") as string),
      lastName: (formData.get("lastName") as string),
      password: (formData.get("password") as string)
    };

    // We have to set pending state after getting form data. See why in the comment above.
    setFormState(pending());
    setFormState(await onSubmit(payload));
  }
  return <>
      <form className="mb-2 flex flex-col gap-5" ref={formRef} onSubmit={handleSubmit}>
        <Name disabled={isPending(formState)} name="firstName" placeholder={{
        da: "Fornavn",
        de: "Vorname",
        en: "First name",
        no: "Fornavn",
        sv: "Förnamn"
      }} required onChange={checkFormValidity} data-sentry-element="Name" data-sentry-source-file="CreateUserForm.component.tsx" />
        <Name disabled={isPending(formState)} name="lastName" placeholder={{
        da: "Efternavn",
        de: "Nachname",
        en: "Last name",
        no: "Etternavn",
        sv: "Efternamn"
      }} required onChange={checkFormValidity} data-sentry-element="Name" data-sentry-source-file="CreateUserForm.component.tsx" />
        <div>
          <Email disabled={isPending(formState)} name="email" ref={emailRef} required onChange={async event => validateEmail(event.target.value)} onClear={async () => validateEmail("")} data-sentry-element="Email" data-sentry-source-file="CreateUserForm.component.tsx" />

          {hasFailed(emailState) ? <EmailErrorMessage status={(emailState.errorMessage as ErrorMessageStatus)} /> : null}
        </div>
        <Password disabled={isPending(formState)} name="password" required onChange={checkFormValidity} data-sentry-element="Password" data-sentry-source-file="CreateUserForm.component.tsx" />

        <CheckboxWithLabel className="peer" id="createUserAcceptTerms" isDisabled={isPending(formState)} isRequired onChange={checkFormValidity} data-sentry-element="CheckboxWithLabel" data-sentry-source-file="CreateUserForm.component.tsx">
          {{
          da: inlineJsx`Jeg accepterer ${termsAndConditionsLink}`,
          de: inlineJsx`Ich akzeptiere die ${termsAndConditionsLink}`,
          en: inlineJsx`I accept the ${termsAndConditionsLink}`,
          no: inlineJsx`Jeg aksepterer ${termsAndConditionsLink}`,
          sv: inlineJsx`Jag accepterar ${termsAndConditionsLink}`
        }[locale]}
        </CheckboxWithLabel>
        <SubmitButton disabled={!isFormValid || isPending(formState)} isPending={isPending(formState)} data-sentry-element="SubmitButton" data-sentry-source-file="CreateUserForm.component.tsx" />
      </form>

      {hasFailed(formState) ? <ErrorMessage status={(formState.errorMessage as ErrorMessageStatus)} /> : null}
    </>;
}