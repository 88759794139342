import type { SiteId } from "@/contexts/site/models/site.types";
import { addTrial } from "./api/add-trial.api";
import { createProfile } from "./api/create-profile.api";
import { fetchProfile } from "./api/fetch-profile.api";
import { mapToAddTrialDto } from "./dtos/map-to-add-trial-dto";
import { mapToCreateProfileDto } from "./dtos/map-to-create-profile-dto";
import { mapToFetchProfileDto } from "./dtos/map-to-fetch-profile-dto";
import type { AddTrialModel } from "./models/add-trial.model";
import type { CreateProfileModel } from "./models/create-profile.model";
import type { FetchProfileModel } from "./models/fetch-profile-model";

export function createProfileApiService(apiDomain: string) {
  return {
    /**
     * Adds a trial to an existing profile for the current domain.
     * A profile can have multiple trials for multiple domains.
     */
    addTrial: async (model: AddTrialModel, siteId: SiteId) =>
      addTrial({
        dto: mapToAddTrialDto(model),
        recaptchaToken: model.recaptchaToken,
        siteId,
      }),

    /**
     * Creates a profile for a user (works across all domains).
     */
    createProfile: async (model: CreateProfileModel) =>
      createProfile({
        apiDomain,
        dto: mapToCreateProfileDto({
          model,
          withTrial: false,
        }),
        recaptchaToken: model.recaptchaToken,
      }),

    /**
     * Creates a profile (across all domains) and adds a trial to that profile
     * for the current domain.
     */
    createProfileWithTrial: async (model: CreateProfileModel) =>
      createProfile({
        apiDomain,
        dto: mapToCreateProfileDto({
          model,
          withTrial: true,
        }),
        recaptchaToken: model.recaptchaToken,
      }),

    /**
     * Fetches the profile for a given user.
     */
    fetchProfile: async (model: FetchProfileModel) =>
      fetchProfile({
        apiDomain,
        dto: mapToFetchProfileDto(model),
        recaptchaToken: model.recaptchaToken,
      }),
  };
}
