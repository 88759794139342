import { clientFetcher } from "@/services/utilities/fetch/client-fetcher";
import type { FetchProfileDto } from "../dtos/fetch-profile-dto.types";

type FetchProfileProps = {
  readonly apiDomain: string;
  readonly dto: FetchProfileDto;
  readonly recaptchaToken: string;
};

export async function fetchProfile({
  apiDomain,
  dto,
  recaptchaToken,
}: FetchProfileProps) {
  try {
    const response = await clientFetcher(
      `${apiDomain}/v2/user/?email=${dto.email}`,
      {
        recaptchaToken,
      },
    );

    // If response is ok, it means that the user already exists.
    return {
      status: response.ok ? "USER_ALREADY_EXISTS" : "NEW_USER",
    };
  } catch {
    return { status: "FAILED" };
  }
}
