import "client-only";

import { clientFetcher } from "@/services/utilities/fetch/client-fetcher";
import { httpStatusCodes } from "@/services/utilities/http/http-status-code.constants";
import type { AddTrialDto } from "../dtos/add-trial-dto.types";
import type { AddTrialResponse } from "../profile-api-types";

type AddTrialFetcherProps = {
  readonly dto: AddTrialDto;
  readonly recaptchaToken: string;
  readonly siteId: string;
};

export async function addTrial({
  dto,
  recaptchaToken,
  siteId,
}: AddTrialFetcherProps): Promise<AddTrialResponse> {
  try {
    const response = await clientFetcher(
      `/api/v1/sites/${siteId}/user/addTrial?articleId=${dto.articleId}`,
      {
        body: dto,
        credentials: "include", // Pass along all relevant cookies
        method: "POST",
        recaptchaToken,
      },
    );

    if (!response.ok) {
      return { status: "FAILED" };
    }

    return response.status === httpStatusCodes.noContent
      ? { status: "TRIAL_ALREADY_USED" }
      : { status: "CREATED" };
  } catch {
    return { status: "FAILED" };
  }
}
