"use client";

import type { InputHTMLAttributes } from "react";
import { useSite } from "@/contexts/site/site.context";
import type { Locale } from "@/ts/locale/locale.types";
import { DefaultInput } from "../components/DefaultInput/DefaultInput.component";

// Using minlength does not trigger validation properly, so we have to use pattern instead:
// https://stackoverflow.com/questions/66896018/html-input-checkvalidity-always-returns-true-even-with-minlength-violations
const nameRegex = ".{2,}";
type NameProps = Omit<InputHTMLAttributes<HTMLInputElement>, "placeholder" | "type"> & {
  readonly hasDescription?: boolean;
  readonly placeholder: Record<Locale, string>;
};
export function Name({
  hasDescription = true,
  placeholder,
  ...rest
}: NameProps) {
  const {
    locale
  } = useSite();
  /* eslint-disable react/jsx-props-no-spreading -- needed so Name input takes the same props as a built-in input */
  return <DefaultInput placeholder={{
    ...placeholder
  }[locale]} {...rest} description={hasDescription ? {
    da: "Skal indeholde mindst 2 tegn",
    de: "Muss mindestens 2 Zeichen enthalten",
    en: "Must contain at least 2 characters",
    no: "Skal inneholde minst 2 tegn",
    sv: "Måste innehålla minst 2 tecken"
  }[locale] : undefined} pattern={nameRegex} data-sentry-element="DefaultInput" data-sentry-component="Name" data-sentry-source-file="Name.component.tsx" />;
  /* eslint-enable */
}