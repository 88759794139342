import type { CreateProfileModel } from "../models/create-profile.model";
import type { CreateProfileDto } from "./create-profile-dto.types";

type MapToCreateProfileProps = {
  readonly model: CreateProfileModel;
  readonly withTrial: boolean;
};

export function mapToCreateProfileDto({
  model,
  withTrial,
}: MapToCreateProfileProps): CreateProfileDto {
  return {
    email: model.email,
    firstName: model.firstName.trim(),
    lastName: model.lastName.trim(),
    password: model.password,
    site: model.site,
    withTrial,
  };
}
